import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  input,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateTime, Settings } from 'luxon';
import {
  NzDatePickerComponent,
  NzDatePickerModule,
} from 'ng-zorro-antd/date-picker';
import { CrmControlDirective } from 'common-module/core';

import { getZonedDateTime, getZonedJSDate } from '../../utils/date-time';

@Component({
  standalone: true,
  selector: 'app-date',
  template: `
    <nz-date-picker
      style="width: 100%"
      nzSize="large"
      [nzFormat]="format() ?? 'MMM dd yyyy'"
      [nzStatus]="status()"
      [nzDisabledDate]="disabledDate()"
      [nzPlaceHolder]="placeholder()"
      [nzAllowClear]="allowClear() ?? false"
      [nzDisabled]="disabled()"
      [nzShowTime]="showTime() ?? false"
      [ngModel]="date()"
      (ngModelChange)="dateChanged($event)"
    ></nz-date-picker>
  `,
  imports: [NzDatePickerModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateComponent extends CrmControlDirective<DateTime> {
  @HostBinding('class')
  protected hostClass = 'ehr-date';

  readonly disabledDate = input<NzDatePickerComponent['nzDisabledDate']>();
  readonly placeholder = input('');
  readonly format = input<string>();
  readonly allowClear = input<boolean>();
  readonly showTime = input<NzDatePickerComponent['nzShowTime']>();

  protected date = computed(() => {
    const value = this.model();

    if (!value) {
      return null;
    }

    return getZonedJSDate(value);
  });

  protected dateChanged(date: Date) {
    if (!date) {
      this.internalUpdateModel(null);
      return;
    }

    const zone = this.model()?.zone ?? Settings.defaultZone;
    this.internalUpdateModel(getZonedDateTime(date, zone));
  }
}
